<template>
  <v-form ref="formElem">
    <translation-locales />
    <v-card>
      <v-card-text>
        <v-row>
          <v-col :cols="cols">
            <h4>Voucher Rule</h4>

            <v-switch
              v-model="form.voucher_rule.send_template"
              color="primary"
              :label="'Send PDF template'"
              :messages="['Send an extra PDF template along with the voucher email. e.g. the medical service PDF.']"
            ></v-switch>

            <template v-if="form.voucher_rule.send_template">
              <v-select
                v-model="form.template_id"
                placeholder="Template"
                :items="templateOptions"
                item-text="title"
                item-value="value"
                outlined
                dense
              ></v-select>
            </template>

            <v-switch
              v-model="form.voucher_rule.show_code"
              color="primary"
              label="Show Code in the email"
              :messages="['On: Show the voucher code in Email; Off: The email does not show the code']"
            ></v-switch>

            <v-switch
              v-model="form.voucher_rule.show_redeem_url"
              color="primary"
              label="Show Redeem Url"
              :messages="[ 'Does the voucher requires further redeemption? ON: goes to & completes Registration via Google form; OFF: no further redeem' ]"
              class="mb-7"
            ></v-switch>

            <template v-if="form.voucher_rule.show_redeem_url">
              <p>Show Conditions:</p>

              <v-switch
                v-model="form.voucher_rule.show_redeem_when_no_participants"
                class="mb-4"
                color="primary"
                :label="'Send when no participants'"
                :messages="['On: This further redeemption is shown only when the participants count = 0 (PWC old version); Off: It shows regardless the participants count. (old+new versions both visible)']"
              ></v-switch>

              <translatable-input
                v-model="form.redeem_url_subject"
                label="redeem subject (in email)"
                placeholder="[channel] Your dental - [order_number] [voucher_code]"
                hint="You may use [channel], [order_number], [voucher_code] as a variable"
              />

              <translatable-input
                v-model="form.redeem_url_description"
                label="redeem description (in email)"
                placeholder="Your dental membership is incompleted..."
              />

              <translatable-input
                v-model="form.redeem_url_cta"
                label="redeem Call To Action"
                placeholder="Registration / 去註冊"
              />

              <v-text-field
                v-model="form.redeem_url"
                label="Redeem URL"
                placeholder="https://ab.com?entry.xxx=[product]&entry.xx2=[work_email]&any=[staff_id]"
                hint="You may use [product], [work_email], [staff_id], [voucher_code] as a variable"
              />
            </template>

            <v-switch
              v-model="form.voucher_rule.show_qr_image"
              color="primary"
              label="Show QR Image in the email"
              :messages="[ 'Normally, suggest just use [voucher_code] as the image content.' ]"
              class="mb-7"
            ></v-switch>

            <v-text-field
              v-if="form.voucher_rule.show_qr_image"
              v-model="form.qr_content"
              label="Qr Image Content (max.255 string)"
              placeholder="[voucher_code]"
              hint="support same variables from redeem url"
            />

            <h4 class="mt-5">
              Make vouchers become...
            </h4>
            <p class="mt-5">
              1) usable or applicable since: <b>{{ form.applicable_at || 'Any time.' }}</b>
              <v-icon
                v-if="form.applicable_at"
                @click="form.applicable_at = null"
              >
                {{ icons.mdiCloseCircle }}
              </v-icon>
            </p>
            <v-date-picker
              v-model="form.applicable_at"
              color="primary"
            ></v-date-picker>

            <p class="mt-5">
              2) stop purchase since: <b>{{ form.last_purchasable_at || 'Never.' }}</b>
              <v-icon
                v-if="form.last_purchasable_at"
                @click="form.last_purchasable_at = null"
              >
                {{ icons.mdiCloseCircle }}
              </v-icon>
            </p>
            <v-date-picker
              v-model="form.last_purchasable_at"
              color="primary"
            ></v-date-picker>

            <p class="mt-5">
              3) expiry date (Reference only): <b>{{ form.expires_at || 'Never expired.' }}</b>
              <v-icon
                v-if="form.expires_at"
                @click="form.expires_at = null"
              >
                {{ icons.mdiCloseCircle }}
              </v-icon>
            </p>
            <v-date-picker
              v-model="form.expires_at"
              color="primary"
            ></v-date-picker>

            <p>For new tests, don't forget to do it in UAT env, and then resend bought voucher to yourself.</p>
          </v-col>
        </v-row>
      </v-card-text>

      <v-card-actions class="justify-center">
        <v-btn
          color="primary"
          :loading="loading"
          @click="validate"
        >
          Submit
        </v-btn>
      </v-card-actions>

      <v-alert
        v-for="msg in errors"
        :key="msg"
        color="warning"
      >
        {{ msg }}
      </v-alert>
    </v-card>
  </v-form>
</template>

<script>
import { fetchVoucherTemplates } from '@/api/product'
import { TranslatableInput } from '@/components'
import { TranslationLocales } from '@/components'
import { useTranslatable } from '@/composables'
import { between, required } from '@core/utils/validation'
import { mdiCloseCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'
import moment from 'moment'

export default {
  components: { TranslationLocales, TranslatableInput },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    cols: {
      type: Number,
      default: 6,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    resource: {
      // default resource
      type: [Object, Boolean],
      default: false,
    },
  },

  setup(props, { emit }) {
    const rule = props.resource ? props.resource.voucher_rule : false

    const initialForm = {
      voucher_rule: {
        show_code: rule ? rule.show_code : true,
        show_redeem_url: rule ? rule.show_redeem_url : false,
        show_redeem_when_no_participants: rule ? rule.show_redeem_when_no_participants : true,
        show_qr_image: rule ? rule.show_qr_image : false,
        send_template: rule ? rule.send_template : false,
      },
      template_id: props.resource ? props.resource.template_id : null,
      redeem_url_subject: useTranslatable(props.resource, 'redeem_url_subject'),
      redeem_url_description: useTranslatable(props.resource, 'redeem_url_description'),
      redeem_url_cta: useTranslatable(props.resource, 'redeem_url_cta'),
      redeem_url: props.resource?.redeem_url || '',
      qr_content: props.resource?.qr_content || '',
      applicable_at: props.resource && props.resource.applicable_at ? moment(props.resource.applicable_at).format('YYYY-MM-DD') : null,
      last_purchasable_at: props.resource && props.resource.last_purchasable_at ? moment(props.resource.last_purchasable_at).format('YYYY-MM-DD') : null,
      expires_at: props.resource && props.resource.expires_at ? moment(props.resource.expires_at).format('YYYY-MM-DD') : null,
    }

    const form = ref({ ...initialForm })

    const formElem = ref(null)

    const validate = () => {
      if (formElem.value.validate()) emit('submit', form.value)
    }

    const templateOptions = ref([])

    fetchVoucherTemplates({ take: 999 }).then(({ data }) => {
      templateOptions.value = data.data.records.map(t => ({
        title: t.nicename,
        value: t.id,
      }))
    })

    return {
      form,
      formElem,
      validate,
      between,
      required,

      templateOptions,

      icons: {
        mdiCloseCircle,
      },
    }
  },
}
</script>
