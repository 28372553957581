<template>
  <div id="user-view">
    <v-row>
      <v-col
        cols="12"
      >
        <a @click="$router.back()">Back</a>

        <v-tabs
          v-model="currentTab"
          show-arrows
          class="user-tabs mt-2"
        >
          <v-tab
            v-for="tab in tabs"
            :key="tab.icon"
            :disabled="tab.disabled"
          >
            <v-icon
              size="20"
              class="me-3"
            >
              {{ tab.icon }}
            </v-icon>
            <span>{{ tab.title }}</span>
          </v-tab>
        </v-tabs>

        <v-tabs-items
          v-if="productData"
          id="user-tabs-content"
          v-model="currentTab"
          class="mt-5 pa-1"
        >
          <v-tab-item>
            <product-overview
              :product="productData"
              @updated="reloadProduct"
              @tab="switchToTab"
            />
          </v-tab-item>

          <v-tab-item>
            <product-media-gallery
              v-if="productData"
              :product="productData"
              @updated="reloadProduct"
            />
          </v-tab-item>

          <v-tab-item>
            <product-sku-list
              :product="productData"
              @updated="reloadProduct"
            />
            <product-package-list
              :product="productData"
              @updated="reloadProduct"
            />
          </v-tab-item>

          <v-tab-item>
            <product-sku-attribute
              :product="productData"
              @change="reloadProduct"
            />
          </v-tab-item>

          <v-tab-item>
            <div class="row mb-2  d-flex align-center pt-5 pr-5 pl-5">
              <h3 class="col-6">
                SKU Pricing
                <span v-if="['service', 'activity'].includes(productData.nature)">Strategy Group</span>
                (in {{ productData.currency }})
              </h3>
              <v-spacer />

              <act-as-channel-options />
            </div>
            <product-sku-price-list
              v-for="sku in productData.skus"
              :key="sku.id"
              :product="productData"
              :sku="sku"
            />
            <p>For <b>tangible</b>, <b>voucher</b> and <b>topup</b> - each SKU has one group and one price.</p>
            <p>For <b>service</b> and <b>activity</b> - each SKU has multiple groups (e.g. Holiday & Weekday), each group may have multiple prices. So you can assign time slots to different time's pricing.</p>
            <p>
              For <b>plan</b> - each SKU has one group and multiple prices.
              <ul>
                <li>You may use different prices for different quantity discount (agent bulk purchase) </li>
                <li>set a new-year non-main price (下年訂閱加價) so that you can set existing membership's next recursion to this new price, and update it as main on the next year.</li>
                <li>Frontend only shows current main price. </li>
                <li>Agents can fetch other non-main prices based on quantity purchased.</li>
                <li>Agents in default select the main price. If you want different min_quantity for agents (min:5) & their customers (min:1), or different price discrimination ($249 bulk-discounted vs $299 customer next recursion), consider separating into two identical SKUs with different price settings. Then you'll set parent_id to customer's SKU in Portal Passes, so that when a agent assigns the membership to his user, the user will be using the customer's SKU with correct non-bluk-discounted price and min_quantity=1 in his next membership recursion.</li>
                <li>
                  <ul>
                    Consider the following price setup:
                    <li>Personal Plan - Agent: $249 min=5 (main), $229 min=20</li>
                    <li>Personal Plan - Customer: $299 min=1 (main)</li>
                    <li>In Portal, set Agent Pass's parent_id to pass of customer (to set customer next billing to $299 min=1)</li>
                    <li>If the customer is set to use price of min=2+, it will NOT checkout recursion successfully, since the customer only need & can purchase quantity of 1!</li>
                  </ul>
                </li>
              </ul>
            </p>
          </v-tab-item>

          <v-tab-item>
            <product-relations
              :product="productData"
            />
          </v-tab-item>

          <v-tab-item>
            <product-seo
              :product="productData"
              @updated="reloadProduct"
            />
          </v-tab-item>

          <v-tab-item>
            <template v-if="['service', 'activity', 'topup'].includes(productData.nature)">
              <v-card>
                <v-card-text>
                  <v-select
                    v-if="highlightSkuId !== 'all'"
                    v-model="highlightSkuId"
                    label="Show SKU:"
                    :items="skuOptions"
                    item-text="title"
                    item-value="value"
                    outline
                  ></v-select>

                  <div v-if="isTopupPackage">
                    <v-alert color="warning">
                      <p>
                        All SKUs in a package-enabled product should have been pointed to <b>another solo SKU 
                          <router-link v-if="topupSku" :to="{ name: 'product-view', query: { tab: 'TIME SLOTS' }, params: { id: topupSku.product_id } }">
                            [{{ t(topupSku.name) }}]
                          </router-link>
                        </b> for <u>re-top-up and application</u> purpose.
                      </p>
                      <p>
                        Hence, you shouldn't manage this time slot. Quota owner will pick time slots from <b> 
                          <router-link v-if="topupSku" :to="{ name: 'product-view', query: { tab: 'TIME SLOTS' }, params: { id: topupSku.product_id } }">
                            [{{ t(topupSku.name) }}]
                          </router-link>
                        </b>.
                      </p>
                    </v-alert>
                  </div>  
                </v-card-text>

                <v-alert icon="$info" v-if="!isTopupPackage && productData && productData.nature === 'topup'">
                  You may set <b>ANY PRICE</b>, because this is a topup product that <u>uses time slot for application only</u>
                </v-alert>
              </v-card>

              <template>
                <product-sku-slot-list
                  v-for="sku in productData.skus"
                  v-if="['all', sku.id].includes(highlightSkuId)"
                  :key="sku.id"
                  :product="productData"
                  :sku="sku"
                  class="mb-5"
                />
              </template>

            </template>
            <template v-else-if="productData.nature === 'voucher'">
              <product-sku-voucher-list
                v-for="sku in productData.skus"
                :key="sku.id"
                class="mb-5"
                :product="productData"
                :sku="sku"
                @updated="reloadProduct"
              />
            </template>
            <template v-else-if="productData.nature === 'plan'">
              <product-sku-plan-list
                v-for="sku in productData.skus"
                :key="sku.id"
                class="mb-5"
                :product="productData"
                :sku="sku"
                @updated="reloadProduct"
              />
            </template>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { ActAsChannelOptions } from '@/components'
import { t } from '@/plugins/i18n'
import router from '@/router'
import store from '@/store'
import {
mdiAlphaABox,
mdiAlphaPBox,
mdiAlphaSBox,
mdiAlphaSBoxOutline,
mdiCalendarClock,
mdiCash100,
mdiClockOutline,
mdiCurrencyUsd,
mdiPictureInPictureBottomRight,
mdiTag,
} from '@mdi/js'
import { computed, onMounted, ref } from '@vue/composition-api'
import ProductMediaGallery from '../media-resource/ProductMediaGallery.vue'
import useProductView from '../useProduct'
import ProductOverview from './product-overview/ProductOverview.vue'
import ProductPackageList from './product-overview/ProductPackageList.vue'
import ProductRelations from './product-overview/ProductRelations.vue'
import ProductSeo from './product-overview/ProductSeo.vue'
import ProductSkuAttribute from './product-overview/ProductSkuAttribute.vue'
import ProductSkuList from './product-overview/ProductSkuList.vue'
import ProductSkuPlanList from './product-overview/ProductSkuPlanList.vue'
import ProductSkuPriceList from './product-overview/ProductSkuPriceList.vue'
import ProductSkuSlotList from './product-overview/ProductSkuSlotList.vue'
import ProductSkuVoucherList from './product-overview/ProductSkuVoucherList.vue'

export default {
  components: {
    ProductOverview,
    ProductSkuList,
    ProductPackageList,
    ProductSkuPriceList,
    ProductSkuSlotList,
    ProductSkuVoucherList,
    ProductSkuPlanList,
    ProductSkuAttribute,
    ProductMediaGallery,
    ProductRelations,
    ProductSeo,

    ActAsChannelOptions,
  },

  watch: {
    '$route.params.id': function () { this.reloadProduct().then(() => this.switchToDefaultTab())}
  },

  setup() {
    const { loadProduct, productData } = useProductView()
    const highlightSkuId = ref('all')
    const highlightSku = computed(() => (productData.value?.skus.find(s => s.id === highlightSkuId.value)))
    const topupSku = computed(() => highlightSku.value?.quota_rule?.rechargeable)
    const skuOptions = computed(() => productData.value?.skus.map(sku => ({ title: `${t(sku.name)} (${sku.sku_number})`, value: sku.id })))
    const isTopupPackage = computed(() => productData.value?.has_package && productData.value?.nature === 'topup')

    const reloadProduct = () => {
      productData.value = null

      return loadProduct(router.currentRoute.params.id).then(() => {
        store.dispatch('merchant/setCurrent', productData.value.merchant)
        const { skus } = productData.value
        highlightSkuId.value = skus.length > 3 ? skus[0].id : 'all'
      })
    }

    // UnRegister on leave

    const tabs = computed(() => {
      const options = [
        { icon: mdiAlphaPBox, title: 'OVERVIEW' },
        { icon: mdiPictureInPictureBottomRight, title: 'GALLERY' },
        { icon: mdiAlphaSBoxOutline, title: 'SKUS' },
        { icon: mdiAlphaABox, title: 'ATTRIBUTES' },
        { icon: mdiCurrencyUsd, title: 'PRICING' },
        { icon: mdiTag, title: 'RELATIONS' },
        { icon: mdiAlphaSBox, title: 'SEO' },

        // { icon: mdiFileOutline, title: 'POLICIES' },
      ]
      if (productData.value && ['service', 'activity', 'topup'].includes(productData.value.nature)) {
        options.push({ icon: mdiCalendarClock, title: 'TIME SLOTS' })
      }
      if (productData.value && productData.value.nature === 'voucher') {
        options.push({ icon: mdiCash100, title: 'VOUCHERS' })
      }
      if (productData.value && productData.value.nature === 'plan') {
        options.push({ icon: mdiClockOutline, title: 'PLANS' })
      }

      return options
    })
    const currentTab = ref(0)

    const switchToTab = tab => {
      const index = tabs.value.findIndex(t => t.title === tab)
      currentTab.value = Math.max(0, index)
    }

    const switchToDefaultTab = () => {
      const tab = router.currentRoute.query.tab || null
      // console.log('swtich to tab', tab)
      switchToTab(tab)
    }

    onMounted(() => reloadProduct().then(switchToDefaultTab))

    return {
      tabs,
      productData,
      reloadProduct,
      switchToDefaultTab,

      currentTab,
      switchToTab,

      highlightSkuId,
      highlightSku,
      topupSku,
      skuOptions,
      isTopupPackage,

      t,
    }
  },
}
</script>