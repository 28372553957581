<template>
  <div>
    <attribute-value-aside
      v-if="typeInMaking"
      :active="true"
      :attribute-type-id="typeInMaking.id"
      @changed="madeValue"
    ></attribute-value-aside>
    <v-form class="attribute-value-form">
      <v-card>
        <v-card-title class="d-flex">
          <slot name="title"></slot>
          <template>
            <v-chip
              v-for="(value, v) in values"
              v-show="form.sync_ids.includes(value.id)"
              :key="value.id"
              :color="missingType(value) ? 'warning' : ''"
              class="ml-2"
            >
              {{ value.value.en }}
              <span v-if="missingType(value)">(Missing Type!)</span>
              <v-icon
                v-if="missingType(value)"
                @click="removeValueId(value.id)"
              >
                {{ icons.mdiCloseCircle }}
              </v-icon>
            </v-chip>
          </template>
          <!-- <a
            v-if="form.sync_ids.length"
            class="ml-2"
            @click="resetIds"
          >Reset</a> -->
        </v-card-title>

        <v-card-text>
          <v-row>
            <v-col
              v-for="type in types"
              :key="type.id"
              cols="3"
            >
              <v-select
                v-model="form.sync_ids"
                placeholder="Select One Value"
                :items="attributeValuesOptions(type.id)"
                item-text="title"
                item-value="value"
                :label="type.name.en"
                hide-details
                multiple
                @change="handleSync"
              ></v-select>
              <a @click="makeUsingType(type)">+new value</a>
            </v-col>
          <!-- <v-col cols="6">
            <v-text-field
              v-model="searchText"
              placeholder="Search Attribute TYPE name"
            />
          </v-col> -->
          </v-row>
        </v-card-text>

        <v-alert
          v-for="msg in errors"
          :key="msg"
          color="warning"
        >
          {{ msg }}
        </v-alert>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import productStore from '@/modules/product/store'
import store from '@/store'
import axios from '@axios'
import { mdiCloseCircle } from '@mdi/js'
import { ref } from '@vue/composition-api'
import AttributeValueAside from './AttributeValueAside.vue'

export default {
  components: { AttributeValueAside },
  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    types: {
      // attribute types that this form must follow.
      type: Array,
      default() {
        return []
      },
    },
    values: {
      // attribute types that this form must follow.
      type: Array,
      default() {
        return []
      },
    },
    selectedIds: {
      // default selected ids
      type: Array,
      default() {
        return []
      },
    },
    syncBasePath: {
      // example: /admin/skus/1/attribute-values/sync
      type: String,
      required: true,
    },
    syncAble: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    syncAble: 'handleSync',
  },

  setup(props, { emit }) {
    const searchText = ref('')

    const matchText = attrValue => {
      const q = searchText.value.toLowerCase()
      const value = JSON.stringify(attrValue.value).toLowerCase()

      return value.includes(q) || props.selectedIds.includes(value.id)
    }

    const attributeValuesOptions = typeId => {
      let options = []
      const values = productStore.state.attributeValues.find(v => v.attribute_type_id === typeId)
      if (values) {
        options = values.records
          .filter(matchText)
          .filter(attrValue => attrValue.attribute_type_id === typeId)
          .map(value => ({ title: value.value.en, value: value.id }))
      }

      return options
    }

    const initialForm = {
      sync_ids: props.selectedIds,
    }

    const form = ref({ ...initialForm })

    const handleSync = () => {
      emit('change', form.value.sync_ids)

      if (!props.syncAble) return

      axios.post(props.syncBasePath, form.value).then(res => {
        console.log('sync-ed', res)

        return Promise.resolve(res)
      })
    }

    const resetIds = () => {
      form.value.sync_ids = []
      handleSync()
    }
    const missingType = value => !props.types.find(t => t.id === value.attribute_type_id)

    const typeInMaking = ref(null)
    const makeUsingType = type => {
      typeInMaking.value = type
    }
    const madeValue = async value => {
      form.value.sync_ids.push(value.id)
      await store.dispatch('product/fetchAttributeValuesByType', {
        take: 999,
        attributeTypeId: value.attribute_type_id,
      })
      await handleSync()
      typeInMaking.value = null
    }

    const removeValueId = id => {
      const index = form.value.sync_ids.indexOf(id)
      form.value.sync_ids.splice(index, 1)
      emit('change', form.value.sync_ids)
    }

    return {
      form,
      searchText,

      attributeValuesOptions,
      handleSync,

      resetIds,
      missingType,

      typeInMaking,
      makeUsingType,
      madeValue,

      removeValueId,

      icons: {
        mdiCloseCircle,
      },
    }
  },
}
</script>

<style lang="scss">
@import '~@/styles/variables';

.attribute-value-form {
  .v-label:not(.v-label--active) {
    color: var(--v-error-base);
  }
}
</style>
