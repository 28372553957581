<template>
  <div>
    <category-aside
      v-if="making"
      :active="true"
      @changed="made"
    ></category-aside>
    <v-form>
      <v-card>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                v-model="form.sync_ids"
                placeholder="Select at least one Category"
                :items="categoriesOptions"
                item-text="title"
                item-value="value"
                label="Categories"
                outlined
                chips
                multiple
                hide-details
                @change="handleSync"
              ></v-select>
            </v-col>
            <v-col cols="6">
              <v-text-field
                v-model="searchText"
                placeholder="Search Category key, name"
              >
                <template v-slot:append>
                  <a @click="making = true">+new</a>
                </template>
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>

        <v-alert
          v-for="msg in errors"
          :key="msg"
          color="warning"
        >
          {{ msg }}
        </v-alert>
      </v-card>
    </v-form>
  </div>
</template>

<script>
import comStore from '@/modules/common/store'
import { t } from '@/plugins/i18n'
import axios from '@axios'
import { } from '@mdi/js'
import { computed, ref } from '@vue/composition-api'
import CategoryAside from './CategoryAside.vue'

export default {
  components: { CategoryAside },

  props: {
    loading: {
      type: Boolean,
      default: false,
    },
    disabledFields: {
      type: Array,
      default() {
        return []
      },
    },
    errors: {
      type: Array,
      default() {
        return []
      },
    },
    selectedIds: {
      // default selected ids
      type: Array,
      default() {
        return []
      },
    },
    syncBasePath: {
      // example: /admin/products/1/sync-categories
      type: String,
      required: true,
    },
    syncAble: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    syncAble: 'handleSync',
  },

  setup(props, { emit }) {
    const searchText = ref('')

    const matchText = cat => {
      const q = searchText.value.toLowerCase()
      const key = cat.key.toLowerCase()
      const name = JSON.stringify(cat.name).toLowerCase()

      return key.includes(q) || name.includes(q) || props.selectedIds.includes(cat.id)
    }

    const categoriesOptions = computed(() => comStore.state.categories.filter(matchText).map(cat => ({ title: `${t(cat.name)} (${cat.code})`, value: cat.id })))

    const initialForm = {
      sync_ids: props.selectedIds,
    }

    const form = ref({ ...initialForm })

    const handleSync = () => {
      if (!props.syncAble) return

      axios.post(props.syncBasePath, form.value).then(res => {
        console.log('sync-ed', res)
        emit('change')

        return Promise.resolve(res)
      })
    }

    const making = ref(false)
    const made = async resource => {
      console.log('made resource', resource)

      form.value.sync_ids.push(resource.id)
      await handleSync()

      making.value = false
    }

    return {
      form,
      searchText,

      categoriesOptions,
      handleSync,

      making,
      made,
    }
  },
}
</script>
